<template>
  <div v-if="getUser" class="info-body">
    <h2>{{ $t('personal-information') }}</h2>
    <div class="info-body__data">
      <div v-if="getUser.city || getUser.region?.name" class="info-body__data-row">
        <img src="@/assets/svg/icon-location.svg" alt="">
        <div class="row-text">
          {{ getUser.city || getUser.region?.name }}
        </div>
      </div>
      <div class="info-body__data-row" v-if="getUser?.role === 'Active' && getUser?.birthDate">
        <img src="@/assets/svg/icon-date-red.svg" alt="">
        <div class="row-text">
          {{ getFullDate(getUser?.birthDate).slice(0,10) }}
        </div>
      </div>
      <div class="info-body__data-row" v-if="getUser.phone">
        <img src="@/assets/svg/icon-phone.svg" alt="">
        <div class="row-text">
          {{ formatPhone(getUser.phone) }}
        </div>
      </div>
      <div class="info-body__data-row">
        <img src="@/assets/svg/icon-mail.svg" alt="">
        <div class="row-text">
          {{ getUser.email }}
        </div>
      </div>
      <div v-if="getUser.role === 'Inspector'" class="info-body__data-row">
        <img src="@/assets/svg/icon-file.svg" alt="">
        <div class="row-text">
          {{ $t('certificate-id', {data: getUser.documentNumber || null}) }}
          <span>{{ $t('valid-until', {data: getFullDate(getUser.documentIssueDate).slice(0, 10) || null}) }}</span>
        </div>
      </div>
      <div v-if="getUser.role === 'Company'" class="info-body__data-row">
        <img src="@/assets/svg/icon-file.svg" alt="">
        <div class="row-text">
          {{ $t('taxpayer-identification-number', {data: getUser.taxNumber || null }) }}
<!--          <span> {{ getDate(getUser.documentIssueDate) || null }}</span>-->
        </div>
      </div>
      <div v-if="getUser.role === 'Lawyer'" class="info-body__data-row">
        <img src="@/assets/svg/icon-file.svg" alt="">
        <div class="row-text">
           {{ $t('diploma-id', {data: getUser.documentNumber || null}) }}
          <span>{{ $t('from', {data: getFullDate(getUser.documentIssueDate).slice(0, 10) || null}) }}</span>
        </div>
      </div>
      <div v-if="getUser.role === 'Government' || getUser.role === 'Company'" class="info-body__data-row">
        <img src="@/assets/svg/icon-file.svg" alt="">
        <div class="row-text">
          {{ getUser.documentNumber || null }}
          <span>{{ $t('valid-until', {data: getFullDate(getUser.documentIssueDate).slice(0, 10) || null}) }}</span>
        </div>
      </div>
    </div>
    <div class="info-body__bottom">
      <div class="info-body__bottom-documents" v-if="getUser.role !== 'Moderator' && getUser.role !== 'Admin' && getUser.role !== 'Active'">
        <p>{{ $t('documents') }}</p>
        <div class="documents">
          <a
              v-for="(item, key) in getUser.documentsSrc"
              :key="key"
              :href="item.fileSrc"
              target="_blank"
              class="document"
          >
            <img

                src="@/assets/svg/icon-docs-grey.svg"
                alt=""
            />
            <div class="document__item-info">
              <p>{{ item.name || item.originalname }}</p>
              <span>{{ getFullDate(item.createdAt)}}</span>
            </div>
          </a>
        </div>
      </div>
      <router-link class="info-body__bottom-button" :to="getButtonLink">
        <ui-button color="error" :disabled="getUser?.status === 'Rejected' || getUser?.status === 'New' || !getUser?.role">{{ getButtonText }}</ui-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {formatPhone, getFullDate} from '@/utils/general'
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "ProfileMainInfoBody",
  methods: {getFullDate},
  components: {UiButton},

  computed: {
    ...mapGetters(['getUser']),

    getButtonText () {
      return this.getUser.role === 'Admin' ? this.$t('support-requests') : this.getUser.role === 'Active' ? this.$t('my-requests') : this.$t('work-requests')
    },

    getButtonLink () {
      // return this.getUser.role === 'Admin' ? '/admin/tech-support' : '/work-requests'
      return this.getUser.role === 'Admin' ? '/admin/tech-support' : this.getUser?.status !== 'Rejected' && this.getUser?.status !== 'New' && this.getUser?.role ? '/work-requests' : ''
    }
  },

  data() {
    return {
      formatPhone,
    }
  }
}
</script>

<style lang="scss" scoped>
.info-body {

  h2 {
    color: #1B1A1F;
    font-size: 16px;
    margin-bottom: 20px;
  }

  &__data {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 30px;
    flex-wrap: wrap;
    max-height: 180px;

    @media (max-width: 1200px) {
      max-height: 100%;
    }
    @media (max-width: 768px) {
      padding-bottom: 20px;
    }


    &-row {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 24px;
        height: 24px;
      }

      .row-text {
        display: flex;
        flex-direction: column;
        gap: 10px;

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 11px;
          color: #BFBDBD;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 30px;

    &-documents {
      display: flex;
      flex-direction: column;
      gap: 20px;

      p {
        color: #1B1A1F;
        font-size: 16px;
      }

      .documents {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @media (max-width: 768px) {
          display: flex;
          gap: 30px;
          overflow-x: scroll;
        }
      }

      .document {
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width: 768px) {
          flex-direction: column;
          max-width: 150px;
        }
        &__item-info {
          display: flex;
          flex-direction: column;
          gap: 5px;

          @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
          }

          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 15px;
            color: #1B1A1F;
            word-break: break-all;

            @media (max-width: 768px) {
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              text-align: center;
              color: #1B1A1F;
            }
          }

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #BFBDBD;

            @media (max-width: 768px) {
              font-weight: 400;
              font-size: 8px;
              line-height: 112.7%;
              color: #BFBDBD;
              width: 42px;
              text-align: center;
            }
          }
        }
      }
    }

    &-button {
      padding-top: 30px;
      border-top: 1px solid #F5F5F5;
    }
  }
}
</style>
